@import "styles/variables.scss";
.houseBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  border: 2px solid $black;
  margin: 0;
  & > img:hover {
    cursor: pointer;
  }
  & > div:nth-of-type(1) {
    & > div:nth-of-type(1) > img {
      -o-object-fit: cover;
      object-fit: cover;
    }
    & > div:nth-of-type(1) > img:hover {
      cursor: pointer;
    }
  }
}

.link {
  &:hover {
    border: 1px solid $primary;
    color: $primary;
  }
}
.link:focus {
  color: $primary !important;
}
.houseBlock > div:nth-child(1) {
  position: relative !important;
  width: 100%;
}

.singleSlide {
  width: 319px;
  margin: 0 auto;
}
.twoSlide {
  width: 319px;
  margin: 0 auto;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  img {
    display: flex;
    margin: 0;
    height: 30px;
  }
  p {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
  }
}

.horizontalStyleSlider {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 0;
}
.backgroundInfo {
  width: 100%;
  height: 60px;
  display: flex;
  position: absolute;
  bottom: 0;
  background: $white;
  opacity: 80%;
  filter: alpha(opacity=70);
  justify-content: space-around;
}
.blockInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  bottom: 20px;
}
.detail {
  margin: 0;
  color: $text-grey;
  font-weight: bolder;
}
.livingSpace {
  position: relative;
  margin: 0;
  color: $text-grey;
  font-weight: bold;
}
.rooms {
  margin: 0;
  color: $text-grey;
  font-weight: bold;
}
.houseImg {
  width: 45%;
  height: 35%;
  position: absolute;
  top: 60px;
  left: 29%;
}
.notDetail {
  top: 50px;
  height: 50%;
}
.detailHouse {
  left: 30%;
  top: 120px;
  height: 40%;
}

.urlImg {
  width: 201px;
  height: 169px;
}

.sliderBlock {
  position: relative;
  display: flex;
  width: 60%;
  margin: 0 auto;
  overflow: hidden;
}

.ImgArrowRight {
  position: absolute;
  z-index: 2;
  top: 100px;
  right: 10px;
  cursor: pointer;
  transform: rotate(180deg);
}

.ImgArrowLeft {
  position: absolute;
  z-index: 2;
  top: 100px;
  left: 10px;
  cursor: pointer;
}

.price {
  display: flex;
  align-items: center;
  img {
    height: 31px;
  }
  p {
    margin: 0;
  }
}

.defaultImg {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 200px;
}
.defaultImg:hover {
  cursor: pointer;
}

.isShowIndicators {
  div {
    ol {
      display: none !important;
    }
  }
}
.indicators {
  div {
    ol {
      display: flex;
      flex-wrap: wrap;
      li {
        background-color: $white;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        opacity: 1;
      }
    }
  }
}

.subSliderImg {
  position: relative;
  width: 100%;
  height: 200px;
  -o-object-fit: contain;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
  & > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.active {
  height: 200px !important;
}

.houseImage {
  height: 500px !important;
  background-color: #f3f3f3;
  & > img {
    height: 100%;
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
}
.houseImage:hover {
  cursor: auto !important;
}
.houseImageFallBack {
  height: 500px !important;
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.mySlider {
  .slick-slider {
    .slick-list {
      width: 90%;
      margin: 0 auto !important;
      .slick-track {
        display: flex !important;
        .slick-slide {
          margin-right: 10px;
        }
      }
    }
  }
}
.offersContainer {
  width: 225px;
  position: relative;
  height: 200px;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .sliderMobile {
    justify-content: center !important;
  }
}

@media screen and (max-width: 420px) {
  .houseImage {
    height: 200px !important;
  }
  .detailHouse {
    top: 60px;
  }
}
