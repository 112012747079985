@import "styles/variables.scss";
.footer {
  flex: 0 0 auto;
}
.footerLogo {
  margin: 0 auto 15px auto;
  width: 143px;
  height: 59px;
}
.iconMail {
  width: 15px;
  height: 15px;
  opacity: 70%;
}
.iconMail:hover {
  opacity: 100%;
}
.icon {
  width: 10px;
  height: 13px;
  opacity: 70%;
}
.iconSocial {
  width: 9px;
  height: 18px;
  opacity: 70%;
}
.iconGoogle {
  width: 18px;
  height: 18px;
  opacity: 70%;
}
.iconGoogle:hover {
  opacity: 100%;
}
.iconSocial:hover {
  opacity: 100%;
}
.globeIcon {
  width: 13px;
  height: 13px;
  opacity: 70%;
}
.socialIco {
  width: 23px;
  height: 23px;
}
.link {
  color: $very-dark-gray;
}
.link:hover,
.link:focus {
  color: $primary;
}
.backgroundColor {
  background: $primary;
}
.footerPosition {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (max-width: 850px) {
  .box {
    flex-direction: column;
    .alignCenter {
      align-items: center !important;
      justify-content: center !important;
    }
  }
}

@media screen and (max-width: 420px) {
  .mobile {
    flex-direction: column;
  }
}
