@import "styles/variables.scss";
/////////////////////////////////////
// downloadExcel.jsx

.excelDownloadPosition {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

///////////////////////////////////////////
// index.jsx
.renderAutoPlacesBackground {
  &Blue {
    background-color: lightblue;
    position: relative;
    z-index: 1;
  }
  &White {
    background-color: white;
    position: relative;
    z-index: 1;
  }
}

.headlineContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.headlineText {
  align-self: flex-start;
  margin: 0 0 10px 0;
  color: grey;
  font-size: 14px;
}

.filterPosition {
  position: relative;
  display: flex;
  width: 100%;
  top: 20px;
}

.startPagePosition {
  top: -70%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
///////////////////////
// Autosuggest placement

.autosuggest {
  top: 66px;
  margin-left: 0;
  z-index: 1;
}

.noPermission {
  height: 50vh;
  width: 100%;
  display: grid;
  place-content: center;
}

// tooltip
.btn {
  position: relative;
  &:hover {
    .tooltip {
      opacity: 1;
    }
  }
}
.tooltip {
  position: absolute;
  top: -80px;
  color: black;
  background-color: rgba(255, 255, 255, 0.9);
  line-height: 1.2;
  width: 200px;
  border: 1px solid rgba(0, 0, 0, 0.671);
  border-radius: 10px;
  padding: 5px;
  opacity: 0;
  transition: all 0.3s;
}
