@import "styles/variables.scss";
.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.priceBox {
  display: flex;
  padding: 10px;
  input {
    width: 100px;
    height: 30px;
    border: 1px solid $light-grayish-blue;
  }
}

@media all and (max-width: 992px) {
  .filterContainer {
    & > div {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 5px;
    }
  }
}
@media all and (max-width: 770px) {
  .priceBox {
    flex-direction: column;
    align-items: center;
    input {
      width: 100%;
    }
  }
}
@media all and (max-width: 420px) {
  .filterContainer {
    flex-direction: column;
  }
}
