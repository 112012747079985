@import "styles/variables.scss";
.dropdownBox {
  position: relative;
  button {
    border-radius: 3px;
    color: $very-dark-gray;
    display: flex;
    justify-content: space-between;
    background-color: inherit;
    border: 1px solid $light-grayish-blue;
    width: 170px;
    height: 43px;
    align-items: center;
  }
  button:focus {
    outline: none;
  }
  & > div:nth-of-type(1) > span:hover {
    cursor: pointer;
    background-color: $primary;
    color: $white;
  }
  & > div:nth-of-type(1) > span:nth-of-type(1):hover {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  & > div:nth-of-type(1) > span:last-child:hover {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
}

.show {
  color: $very-dark-gray;
  background-color: $white;
  position: absolute;
  top: calc(100% + 5px);
  border: 1px solid $light-grayish-blue;
  display: flex !important;
  flex-direction: column;
  font-size: 16px;
  animation: showOptions 250ms ease 0s 1 normal none running;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 2;
  padding: 10px;
  span {
    padding: 5px 10px;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div {
    margin-top: auto;
  }
}
@keyframes showOptions {
  0% {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0 0;
  }
}

@media all and (max-width: 420px) {
  .dropdownBox {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
@media all and (max-width: 770px) {
  .show {
    width: 170px;
  }
}
